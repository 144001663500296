import React from 'react';
import Layout from '../client/common/layout/Layout';
import SEO from '../client/common/layout/SEO';

const NotFound = () => (
  <Layout mainClass="page-container page-padding">
    <SEO title="Not Found" description="Not Found" statusCode={404} hasSuffix />
    <h1>Not Found!</h1>
  </Layout>
);

export default NotFound;
